import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const CtaBarStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-cta-bar-original,
      .leshen-sticky-bar .sticky {
        background-color: #96fff5 !important;
        padding: ${theme.spacing.md} 0;

        button {
          background-color: #ff0037;
          color: #fff;
          border-radius: 4px !important;
          text-transform: uppercase;
          border-radius: 32px !important;

          &:hover {
            text-decoration: none;
            background-color: #cc002c;
            color: #fff;

            svg {
              fill: #fff;
            }
          }

          svg {
            fill: #fff;
            transition: all 0.3s;
          }
        }
      }
    `}
  />
))

export default CtaBarStyles
